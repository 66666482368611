import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby"

import mediaqueries from "../styles/media";
import Image from '../components/Image/Image';
import Headings from "../components/Headings";
import Anchor from '../components/Anchor';
import Section from "../components/Section";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import Paragraph from "../components/Paragraph";
import MainImage from '../../content/posts/images/digital tools.jpeg'


function ToolsPage({ location, pageContext }) {
  return (
    <Layout>
      <SEO pathname={`Digital Tools - Nucks`} />
        <Section narrow>
        <Container>
          <ProfileImage src={MainImage} width="150px" height="150px" alt="Cameron Nuckols" />
          <Message>
          <Greeting>The Best Digital Tools</Greeting>
          <SubHeading>This is a non-exhaustive list of the tools I use on a regular basis besides ChatGPT.</SubHeading><br/>
          <SubHeading>
            <b>Newsletters</b><br/>
            <li>
            <Anchor
              target="_blank"
              rel="noopener nofollow"
              href="https://beehiiv.com?via=nucks"
            >Beehiiv - Sending out newsletters</Anchor><br/><br/>
            </li>

            <b>Forms</b><br/>
            <li>
            <Anchor
              target="_blank"
              rel="noopener nofollow"
              href="https://deformity.ai?via=nucks"
            >Deformity - AI-driven forms that stand out</Anchor><br/><br/></li>
            <b>Websites</b><br/>   
            <li>
            <Anchor
              target="_blank"
              rel="noopener nofollow"
              href="https://webflow.grsm.io/3070203"
            >Webflow - One of the best no-code website builders</Anchor><br/></li>
            <li>
            <Anchor
              target="_blank"
              rel="noopener nofollow"
              href="https://popsy.co?via=nucks"
            >Popsy - Notion website builder for simple MVP websites</Anchor><br/></li>
            <li>
            <Anchor
              target="_blank"
              rel="noopener nofollow"
              href="https://coframe.ai?via=nucks"
            >Coframe - Automatic A/B Testing</Anchor><br/><br/></li>

            <b>Bookmarks</b><br/>
            <li>
            <Anchor
              target="_blank"
              rel="noopener nofollow"
              href="https://rewind.ai?via=nucks"
            >Rewind - Have perfect memory</Anchor><br/><br/></li>

            <b>Research</b><br/>
            <li>
            <Anchor
              target="_blank"
              rel="noopener nofollow"
              href="https://gummysearch.com?via=nucks"
            >Gummysearch - Find what people are saying on Reddit</Anchor><br/></li>
            <li>
            <Anchor
              target="_blank"
              rel="noopener nofollow"
              href="https://perplexity.ai?via=nucks"
            >Perplexity - ChatGPT with personalization and citations</Anchor><br/><br/></li>
          </SubHeading>
          </Message>
        </Container>
      </Section>
    </Layout>
  );
}

export default ToolsPage;

const SubscribeButtonContainer = styled('div')`
  margin-top: 25px;

  ${mediaqueries.tablet`
    margin-bottom: 20px;
  `}

  ${mediaqueries.phablet`
    margin-bottom: 30px;
  `}
`

const Container = styled('div')`
  display: grid;
  grid-template-columns: 200px auto;

  ${mediaqueries.tablet`
    grid-template-columns: auto;
    margin-top: 80px;
  `}

  ${mediaqueries.phablet`
    margin-top: 80px;
  `}
`

const Message = styled('div')`
  margin-top: 130px;

  ${mediaqueries.tablet`
    margin-top: 25px;
  `}
`

const ProfileImage = styled(Image)`
  border-radius: 50%;
  width: 150px;
`

const InsideLink = styled(Link)`
  transition: ${p => p.theme.colorModeTransition};
  color: ${p => p.theme.colors.accent};

  &:visited {
    color: ${p => p.theme.colors.accent};
    opacity: 0.85;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
}
`

const Greeting = styled(Headings.h1)`
  font-family: inherit;

  ${mediaqueries.tablet`
    margin-bottom: 20px;
  `}

  ${mediaqueries.phablet`
    margin-bottom: 20px;
  `}
`

const SubHeading = styled(Paragraph)`
  margin: 25px 0px 0px 0px;
  max-width: 650px;
  font-size: 19px;

  ${mediaqueries.phablet`
    padding: 0px;
  `}
`
